import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const UUTestReport = makeShortcode("UUTestReport");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Bruk av bølge`}</h2>
    <p>{`Bølgen er et sentralt element i SpareBank 1s visuelle profil. Vi bruker den som et identitetsbærende og dekorativt innslag, for eksempel som bakgrunn på websider.`}</p>
    <DSExample name="waves_wave" mdxType="DSExample" />
    <UUTestReport reportID="Wave_Wave" mdxType="UUTestReport" />
    <h2>{`Valg av farge`}</h2>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`color`}</code>{`-propertien er obligatorisk og må sendes med for å sette farge på selve bølgen. Dersom man ønsker å sette en bakgrunnsfarge på resten av containeren kan man sende med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bgColor`}</code>{`-propertien.`}</p>
    <p>{`Gyldige farger som kan sendes med i `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`color`}</code>{` og `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bgColor`}</code>{` er:`}</p>
    <ul className="ffe-bullet-list">
    <li className="ffe-bullet-list__item">hvit</li>
    <li className="ffe-bullet-list__item">frost-30</li>
    <li className="ffe-bullet-list__item">sand-30</li>
    <li className="ffe-bullet-list__item">sand-70</li>
    <li className="ffe-bullet-list__item">syrin-30</li>
    <li className="ffe-bullet-list__item">syrin-70</li>
    <li className="ffe-bullet-list__item">vann-30</li>
    <li className="ffe-bullet-list__item">vann</li>
    <li className="ffe-bullet-list__item">fjell</li>
    </ul>
    <p>{`Du kan også bestemme hvilken farge som skal brukes i darkmode med å sende med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`darkmodeColor`}</code>{` og/eller `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`bgDarkmodeColor`}</code>{`.
Gyldige farger som kan brukes i darkmode er: `}</p>
    <ul className="ffe-bullet-list">
    <li className="ffe-bullet-list__item">svart</li>
    <li className="ffe-bullet-list__item">natt</li>
    </ul>
    <DSExample name="waves_wave_color" mdxType="DSExample" />
    <DSExample name="waves_wave_bgColor" mdxType="DSExample" />
    <h2>{`Snu bølgen`}</h2>
    <p>{`I tilfeller der du ønsker å bruke bølgen opp ned kan du sende med med `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flip`}</code>{`-propertyen, som vil rotere bølgen 180 grader.`}</p>
    <DSExample name="waves_wave_flip" mdxType="DSExample" />
    <h2>{`Plassering`}</h2>
    <p>{`Bølgen kan forskyves horisontalt ved hjelp av `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`position`}</code>{`-propertyen.`}</p>
    <DSExample name="waves_wave_position" mdxType="DSExample" />
    <h2>{`Layout eksempel`}</h2>
    <p>{`Under ser du et eksempel på hvordan bølgen kan brukes i sammenheng med grid for å bygge en layout.`}</p>
    <DSExample name="waves_wave_layout_example" mdxType="DSExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      